import * as React from "react"
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import LearnMore from '../components/learnmore';
import SideBar from '../components/sidebar';
import SpeakWithAdvisor from '../components/ui/SpeakWithAdvisor';

const RxInformation = ({ location }) => (
	<Layout location={location} heading1="Prescription Drug Coverage" heading2="Manage your medications with savings and convenience.">
    	<Seo title="Rx Information" meta={[{description: 'UVM Health Advantage plans include Part D prescription drug coverage, making it easy to manage your prescription needs and expenses with one health plan.'}]} bodyclass="rx-information subpage" />
		
		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">
					<main>
						<h3>Part D Prescription Drug Coverage </h3>
						<p>UVM Health Advantage plans include Part D prescription drug coverage, making it easy to manage your prescription needs and expenses with one health plan.</p>
						<p><a href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/mvp-health-care-medicare-comprehensive-formulary-uvm-2024.pdf" className="download">View UVM Health Advantage&rsquo;s 2024 Medicare Part D formulary (list of covered drugs) (PDF)</a> (Updated 10/1/23)</p>
						
						<h4>$0 Preferred Generic Drugs</h4>
						<p>Tier 1 of the Formulary, Preferred Generic Drugs, covers commonly used generic medications at no additional cost. Talk to your doctor to see if these medications may be right for you.</p>

						<h4>Important Message About What You Pay for Vaccines</h4>
						<p>Our plan covers most Part D vaccines at no cost to you, even if you haven&rsquo;t paid your deductible.</p>

						<h4>Important Message About What You Pay for Insulin</h4>
						<p>You won&rsquo;t pay more than $35 for a one-month supply of each insulin covered by our plan, no matter what cost-sharing tier it&rsquo;s on, even if you haven&rsquo;t paid your deductible.</p>
						
						<h4>Convenient Pharmacy Access</h4>
						<p>With UVM Health Advantage, you have access to thousands of participating pharmacies, including UVMMC Specialty Pharmacy and Retail Pharmacy and all major pharmacy chains. Just show your Member ID card any time you fill a prescription. Prescriptions filled at non-contracted pharmacies are covered only in certain situations.</p>
						<p><a href="https://www.medicareplanrx.com/jccf/mvp_pharmacy_23.html" className="find">Find a pharmacy near you</a></p>
						<p><a href="https://www.mvphealthcare.com/-/media/project/mvp/healthcare/documents-by-section/plans-documents/medicare-and-uvm-health/mvp-health-care-medicare-pharmacy-directory-english-2023.pdf" className="download">View MVP&rsquo;s 2023 Medicare Advantage Pharmacy Directory (PDF)</a></p>

						<h4>Free Home Delivery (and Extra Savings)</h4>
						<p>Take advantage of savings on prescriptions you take regularly with the CVS Caremark Mail Service Pharmacy. Get a three-month supply of medication for only two co-pays. Shipping is free!</p>

						<h4>SimpleDose and PillPack Make Medications Simpler</h4>
						<p>Get 30-day supplies of eligible medications prepared in one easy box, and sorted and labeled by dose, day and when to take. Take advantage of SimpleDose<sup>&trade;</sup> from CVS Pharmacy<sup>&reg;</sup>, available for pick-up from your local CVS store or sent to your home for free. PillPack is also available with free shipping to your home.</p>
						
						<h4>Personalized, Convenient Medication Support with UVM Medical Center Specialty and Retail Pharmacy</h4>
						<p>The pharmacy team at UVM Medical Center will work closely with your doctors and nurses to provide you a personalized and convenient experience with delivery right to your home! With UVM Medical Center, your medications will be renewed with ease and prescriptions can even be synced so that they all renew together. Plus if you have one or more prescriptions at another pharmacy, they will coordinate and transfer them for you. All delivered right to your door!</p>

						<h3 className="small">UVM Health Advantage 2024 Medicare Part D Coverage Details</h3>
													
						<h4>Initial Coverage Period</h4>
						<p><a href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/mvp-uvm-2024-prescription-drug-benefits.pdf" target="_blank" rel="noreferrer noopener" className="download">Download details on costs for a 30-day supply</a> from a retail pharmacy. Costs vary by plan.</p>

						<h4>Coverage in the Gap </h4>
						<p>If your total drug costs in 2024 reach $5,030, you enter the Coverage Gap (also known as the donut hole). In the Coverage gap you pay 25% for generic drugs and 25% for Medicare-contracted brand-name drugs.</p>

						<h4>Reaching the $8,000 Catastrophic Coverage Limit in 2024:</h4>
						<p>If your true out-of-pocket costs reach $8,000 in 2024, your cost for prescriptions is reduced. You pay $0 for drugs in all tiers for the rest of the calendar year.</p>
						<p>Note: If you do not join a Medicare Part D plan when you first become eligible for Medicare, or do not have coverage as good as Medicare&rsquo;s, you may have to pay a penalty if you join at a later date. This penalty will apply for as long as you are enrolled in Part D coverage. <a href="##">Learn more</a>.</p>
						<p><a href="https://www.mvphealthcare.com/wp-content/uploads/download-manager-files/mvp-health-care-medicare-comprehensive-formulary-uvm-2024.pdf" className="download">View UVM Health Advantage&rsquo;s 2024 Medicare Part D formulary (list of covered drugs) (PDF)</a> (Updated 10/1/23)</p>
						<p><a href="https://sit3xws85.caremark.com/jccf/mvp_uvm_formulary_23.html" class="external">Find out how your drug is covered.</a></p>
						
						<h4>Help With Drug Costs</h4>
						<p>You may be eligible for certain state and federal programs that reduce your monthly prescription drug premium or co-pays.</p>
						
						<p><b>Low Income Subsidy</b><br />
						To see if you qualify, call <b>1-800-MEDICARE (1-800-633-4227)</b> 24 hours a day, seven days a week. Or call the Social Security Office at <b>1-800-772-1213</b> Monday&ndash;Friday, 7&thinsp;a.m.&ndash;7&thinsp;p.m. (TTY 1-800-325-0778). You can also call your state Medicaid office.</p>

						<p><b>Pharmaceutical Assistance Programs</b><br />
						To ask if you qualify, call:</p>

						<p><b>Vermont VPharm</b><br />
						(Vermont Prescription Assistance)<br />
						1-800-250-8427 (TTY 1-888-834-7898)</p>

						<p><b>New York State EPIC</b><br />
						(Elderly Pharmaceutical Insurance Coverage)<br />
						1-800-332-3742 (TTY 1-800-290-9138)</p>

						
						<LearnMore />
						<SpeakWithAdvisor />
						
					</main>
					<SideBar location={location} />

				</div>
			</div>
		</div>
	</Layout>
)

export default RxInformation
